<template>
  <div>
    <form @submit.prevent="handleValidate">
      <b-card>
        <b-row>
          <b-col cols="4">
            <div class="image-upload">
              <v-file-input
                hide-input
                v-model="file"
                accept="image/png, image/jpeg"
                class="icon-edit"
                @change="onUpload"
                prepend-icon="mdi-pencil-outline"
                truncate-length="15"
              ></v-file-input>
              <v-img :src="preview" :aspect-ratio="2 / 1"></v-img>
            </div>
            <div class="error text-error">
              <p v-if="!isImageValid" class="mb-1">
                Kích thước ảnh tối đa nhỏ hơn 512kb
              </p>
              <p v-if="!isImage" class="mb-1">
                Định dạng ảnh phải là JPG hoặc PNG
              </p>
            </div>
            <label
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <span class="text-bold">Hình ảnh khảo sát, tỷ lệ 2:1</span>
              <span class="d-block font-italic">
                Tối đa 512kb, kích thước đề xuất 640:320px</span
              ></label
            >
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <basic-input
                  label="Mã khảo sát"
                  placeholder="Nhập mã khảo sát"
                  name="code"
                  maxlength="50"
                  :value.sync="form.code"
                  required
                  :state="validateState('code')"
                  :invalidFeedback="errors.first('code')"
                  v-validate="'required|alpha_num|max:50'"
                  data-vv-as="Mã khảo sát"
                ></basic-input>
              </b-col>
              <b-col>
                <basic-input
                  label="Tên khảo sát"
                  placeholder="Nhập tên khảo sát"
                  name="name"
                  maxlength="100"
                  :value.sync="form.name"
                  required
                  :state="validateState('name')"
                  :invalidFeedback="errors.first('name')"
                  v-validate="'required|max:100'"
                  data-vv-as="Tên khảo sát"
                ></basic-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <basic-text-area
                  label="Mô tả"
                  placeholder="Nhập nội dung mô tả"
                  name="description"
                  maxlength="500"
                  :value.sync="form.description"
                  required
                  :state="validateState('description')"
                  :invalidFeedback="errors.first('description')"
                  v-validate="'required|max:500'"
                  data-vv-as="Mô tả"
                />
              </b-col>
            </b-row>
            <b-row v-if="isUpdate">
              <b-col cols="6">
                <basic-select
                  label="Trạng thái"
                  placeholder="--- Chọn trạng thái ---"
                  name="type"
                  :solid="false"
                  :allowEmpty="true"
                  value-label="text"
                  track-by="value"
                  :options="statuses"
                  :value.sync="form.status"
                  disabled
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
      <basic-subheader title="Biên tập câu hỏi" class="my-6">
        <template v-slot:actions>
          <b-button
            v-if="sections.length"
            class="btn btn-green ml-3"
            type="button"
            @click="previewSection"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/eye.svg" />
            </span>
            Xem trước
          </b-button>
          <b-button
            class="btn btn-success ml-3"
            type="button"
            @click="addSection"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
            </span>
            Thêm phần mới
          </b-button>
        </template>
      </basic-subheader>
      <template v-for="(section, index) in sections">
        <CollapseQuestion
          :id="section.id"
          :ref="`section-${section.id}`"
          :section-number="index + 1"
          :key="index"
          :section="section"
          @add-question="addQuestions"
        />
      </template>
    </form>
    <ModalQuestion v-if="showModal" :id="sectionId" @reset="resetModal" />
    <ModalPreview v-if="showModalPreview" @reset="resetModalPreview" />
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import { ADD_SECTIONS } from '@/core/services/store/survey.module';
import { createNamespacedHelpers } from 'vuex';
import { MODAL_STATUS } from '@/core/plugins/constants';
const { mapMutations, mapState } = createNamespacedHelpers('survey');
export default {
  name: 'SurveyForm',
  components: {
    ModalQuestion: () => import('./Modal.vue'),
    ModalPreview: () => import('./ModalPreview.vue'),
    CollapseQuestion: () => import('./CollapseQuestion.vue'),
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      showModalPreview: false,
      file: null,
      isImageValid: true,
      isImage: true,
      preview: null,
      form: {
        code: null,
        name: null,
        description: null,
        status: null,
        isBeta: false,
      },
      sectionId: null,
      statuses: [
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'Inactive',
          value: 0,
        },
        {
          text: 'Draft',
          value: 2,
        },
        {
          text: 'Beta',
          value: 3,
        },
      ],
    };
  },
  computed: {
    ...mapState(['sections', 'deletedSections', 'deletedQuestions']),
    disabledCheckboxBeta() {
      return this.form.status?.value === 1;
    },
  },
  watch: {
    data: {
      handler() {
        this.setupData();
      },
      // deep: true,
    },
  },
  mounted() {
    this.setupData();
  },
  methods: {
    ...mapMutations({ ADD_SECTIONS }),
    setupData() {
      if ((this.isUpdate || this.isCopy) && this.data) {
        this.form = { ...this.data };
        this.form.status = this.statuses.find(
          (el) => el.value === this.data.status,
        );
        this.preview = this.data.image.url;
      }
    },
    addSection() {
      const newItem = {
        name: null,
        questions: [],
        id: this.$uuid.v1(),
      };
      if (this.isUpdate) {
        newItem['modelStatus'] = MODAL_STATUS.NEW;
      }
      this.ADD_SECTIONS(newItem);
    },
    onUpload() {
      this.isImage = ['image/jpeg', 'image/png'].includes(this.file.type);
      this.isImageValid = this.file.size <= 512000;
      if (this.isImage && this.isImageValid) {
        return (this.preview = URL.createObjectURL(this.file));
      }
      return (this.preview = null);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    addQuestions(id) {
      this.showModal = true;
      this.sectionId = id;
      this.$store.commit('context/setModal', true);
    },
    resetModal() {
      this.showModal = false;
      this.sectionId = null;
      this.$store.commit('context/setModal', false);
    },
    async handleValidate() {
      let isValid = await this.validateSections();
      this.$validator.validateAll().then((result) => {
        if (result && isValid) {
          this.submit();
        }
        return;
      });
    },
    validateSections() {
      return new Promise(async (resolve) => {
        let arrInValidValue = [];
        for (let index = 0; index < this.sections.length; index++) {
          const el = this.sections[index];
          const section = this.$refs[`section-${el.id}`][0];
          const result = await section.handleValidate();
          !result && arrInValidValue.push({ isValid: result });
        }
        resolve(arrInValidValue.length <= 0);
      });
    },
    submit() {
      const params = {
        code: this.form.code,
        name: this.form.name,
        description: this.form.description,
      };
      if (this.file) {
        params['file'] = this.file;
      }
      if (!this.isUpdate) {
        params['sections'] = this.getSections();
      } else {
        params['id'] = this.id;
        params['sections'] = [...this.sections, ...this.deletedSections].map(
          (el, index) => {
            const params = {
              ...el,
              id: el.modelStatus === MODAL_STATUS.NEW ? null : el.id,
              order: index + 1,
              surveyId: this.id,
            };
            params['sectionQuestions'] = this.convertQuestions(
              [...el.questions, ...this.deletedQuestions],
              params.id,
            );
            delete params.questions;
            return params;
          },
        );
      }
      this.$emit('submit', params);
    },
    convertQuestions(questions, surveySectionId) {
      if (!questions.length) return [];

      const newQuestions = questions.map((el, index) => {
        return {
          surveySectionId,
          surveyQuestionId: el.id,
          modelStatus: el.modelStatus,
          order: index + 1,
        };
      });
      return newQuestions;
    },
    getSections() {
      const sections = cloneDeep(this.sections);
      sections.forEach((el, index) => {
        delete el.id;
        el.sectionQuestions = el.questions.map((el, index) => {
          return {
            surveyQuestionId: el.id,
            order: index + 1,
          };
        });
        el.order = index + 1;
      });
      return sections;
    },
    previewSection() {
      this.showModalPreview = true;
      this.$store.commit('context/setModal', true);
    },
    resetModalPreview() {
      this.showModalPreview = false;
      this.$store.commit('context/setModal', true);
    },
  },
};
</script>
<style lang="scss">
.image-upload {
  width: 240px;
  height: 120px;
  background-color: #f3f6f9;
  box-shadow: 0px 4px 4px 0px #000000 10%;
  position: relative;
  margin: auto;
}
.text-error {
  color: #ff5756;
  text-align: center;
  margin-top: 20px;
}
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 18px);
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}
</style>

<style lang="scss" scoped>
.btn-green {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}
</style>
